


















































import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue';
import DialogoDeEdicaoDeBandeiraDeCartao from '@/components/venda/DialogoDeEdicaoDeBandeiraDeCartao.vue';
import { BandeirasDeCartao, Page, Pageable } from '@/models';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore';
import { FindBandeirasDeCartaoUseCase } from '@/usecases';
import { CancelToken } from 'axios';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
@Component({
	components: {
		DataTableDeCrudPaginado,
		DialogoDeEdicaoDeBandeiraDeCartao,
	},
})
export default class TelaDeCadastroDeBandeiras extends Vue {
	@Ref() dataTablePaginado!: DataTableDeCrudPaginado
	@Ref() dialogoDeEdicaoDeBandeiraDeCartao!: DialogoDeEdicaoDeBandeiraDeCartao
	findBandeirasUseCase = new FindBandeirasDeCartaoUseCase()
	busca = ''
	bandeiras: BandeirasDeCartao[] = []
	carregando = false
	editando = false
	headers = [
		{ text: 'Bandeira', value: 'nome' },
	]
	abrirDialogoDeEditarBandeira(indice: number) {
		this.editando = true
		const bandeira = this.bandeiras[indice]
		this.dialogoDeEdicaoDeBandeiraDeCartao.mostrar(bandeira)
	}
	abrirDialogoDeCriarBandeira() {
		try {
			if(!this.podeCriarBandeira) 
				throw new Error('Necessário permissão para criar')
			this.editando = false
			const bandeira = {id:'',nome:''}
			this.dialogoDeEdicaoDeBandeiraDeCartao.mostrar(bandeira)
		} catch(error) {
			AlertModule.setError(error)
		}
	}
	
	async buscarPagina(paginavel: Pageable, parametros: {
		busca?: string
	}, cancelToken: CancelToken): Promise<Page<BandeirasDeCartao>> {
		const pagina = await this.findBandeirasUseCase.findAll({
			...parametros,
			...paginavel,
		}, {
			cancelToken,
		})
		this.bandeiras = pagina.content
		return pagina
	}

	get podeCriarBandeira() {
		return UserLoginStore.permiteRegraDeNegocio('pode-cadastrar-bandeiras')
	}

	salvarBandeira(bandeiraSalva: BandeirasDeCartao) {
		const indice = this.bandeiras.findIndex(bandeira => bandeira.id === bandeiraSalva.id)

		if (indice >= 0) {
			this.bandeiras.splice(indice, 1, bandeiraSalva)
		} else {
			this.bandeiras.push(bandeiraSalva)
		}
	}

	@Watch('busca')
	novaBusca() {
		this.dataTablePaginado.reiniciar({
			busca: this.busca,
		})
	}
	@Watch('carregando')
	onChangeMostrar() {
		this.dataTablePaginado.reiniciar({
			busca: this.busca,
		})
	}
}
